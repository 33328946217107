export default {
  sections: {
    contactInformation: 'Informação de contacto',
    personalData: 'Dados perssoais',
    emailAndPhone: 'Email e telefone',
    address: 'Morada',
    generalData: 'Dados gerais',
    information: 'Informação',
    fiscalData: 'Dados de faturação',
    fiscalAddress: 'Morada de faturação',
    contactPerson: 'Pessoa de contacto',
    rates: 'Tarifas',
    networkRate: 'Tarifas de rede',
    shopRate: 'Tarifas de loja',
    salesRepresentative: 'Comercial',
    capabilities: 'Habilitações',
    status: 'Estado',
    openingSchedule: 'Horário de abertura',
    schedule: 'Horário',
    commissions: 'Comissões',
    commissionsData: 'Dados de comissões',
    salesSettings: 'Configuração de vendas',
  },
  contactForm: {
    nameSurname: 'Nome e Apelido',
    number: 'Telefone',
    email: 'Email',
  },
  infoSections: {
    zones: 'Podes seleccionar mais do que uma zona.',
    rateHierarchy: {
      global:
        'Tarifas gerais para as redes e lojas que não têm tarifas específicas. <br/><br/> <b>Estas tarifas aplicam-se a expedições de logística directa e inversa.</b>',
      network:
        'Introduzir tarifas expecíficas para que nesta rede se paguem valores diferentes à rede e suas lojas. <br/><br/> Se não seleccionar tarifas por defeito para aplicar a configuração geral.',
      shop: 'Introducir tarifas expecificas para que en esta tienda se paguen precios diferentes a la red y a la tienda. <br/><br/> Si no seleccionar tarifas por defecto para aplicar la configuración de la red o si no la hay la global.',
    },
    networkRate: {
      info: 'O valor que se paga à <b>tienda</b> por expedição é a soma dos valores de cada volume, em que cada volume se calcula assim: <br/><br/> <b>Preço por volume = Preço Fixo + (Peso do volume - Limiar) * Preço por kg adicional</b>',
    },
    shopRate: {
      info: 'O valor que se paga à <b>tienda</b> por expedição é a soma dos valores de cada volume, em que cada volume se calcula assim: <br/><br/> <b>Preço por volume = Preço Fixo + (Peso do volume - Limiar) * Preço por kg adicional</b>',
    },
    schedule:
      'Para cada dia coloque um horário se não fechar para almoço, coloque os dois se fecharem para almoço, ou não coloque nada se nesse dia a loja estiver fechada',
  },
  labels: {
    name: 'Nome',
    username: 'Nome de utilizador',
    dni: 'DNI',
    nif: 'NIF',
    idNum: 'DNI',
    password: 'Palavra-passe',
    tooglePassword: 'Ver palavra-passe',
    loginEnabled: 'Habilitado',
    squareCode: 'Código plaza',
    clientCode: 'Código cliente',
    parcelShopId: 'Parcel Shop Id',
    reportOperations: {
      label: 'Redes',
      status: 'Situação',
    },
    email: {
      label: 'Etiqueta',
      address: 'Email',
    },
    dialogs: {
      title: 'ATENÇÃO',
      capabilitiesNotSelected:
        'Ao mudar duma rede premium as habilitações foram apagadas, por favor assinale as habilitações da loja na nova rede.',
      buttonOK: 'OK',
    },
    phone: {
      label: 'Etiqueta',
      number: 'Telefone',
    },
    address: {
      address: 'Morada',
      postalCode: 'Código postal',
      locality: 'Localidade',
      country: 'País',
      latitude: 'Latitude',
      longitude: 'Longitude',
      door: 'Nº de porta',
    },
    searchGoogle: 'Procurar morada no Google',
    zones: 'Zonas',
    enabled: 'Habilitado',
    businessName: 'Nome da loja',
    activity: 'Actividade',
    retention: 'Retenção',
    iban: 'IBAN',
    paymentMethod: 'Método de pagamento',
    dueDays: 'Dias de vencimento',
    useDefaultValues: 'Usar tarifas por defeito',
    networkRate: {
      constant: 'Preço fixo',
      variableStart: 'Limite de peso em kg',
      variable: 'Preço por kg adicional',
    },
    shopRate: {
      constant: 'Preço fixo',
      variableStart: 'Limite de peso em kg',
      variable: 'Preço por kg adicional',
    },
    countries: {
      spain: 'Espanha',
      portugal: 'Portugal',
    },
    titleExcelDialog: 'Atenção',
    contextExcelDialog: 'O resultado seguinte não devolve dados, tente alterar os parâmetros do relatório.',
    accept: 'Aceitar',
    network: 'Rede',
    zone: 'Zona',
    id: 'Uuid',
    timezones: 'Zonas horarias',
    isEco: 'Loja Eco',
    isGold: 'PS Gold',
    QRDinamic: 'QR Dinamico',
    isRadical: 'PS Radical',
    openingRepresentative: 'Representante Comercial',
    countryShop: 'País das lojas',
    since: 'Since',
    to: 'Hasta',
    managingRepresentative: 'Gestor Comercial',
    deliver: 'Entrega',
    pickup: 'Recolha',
    reason: 'Motivo',
    definitiveClosure: 'Fecho definitivo',
    temporaryClosure: 'Fecho temporário',
    switchSchedule: 'Mismo horario de lunes a viernes',
    firstSchedule: 'Horário',
    secondSchedule: 'Segundo horário',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    weekly: 'Segunda a Sexta',
    all: 'Todos',
    incoming: 'Por chegar',
    inShop: 'Na Loja',
    inShopExpired: 'Expirado',
    delivered: 'Entregue',
    searchByName: 'Procurar por nome',
    commissions: {
      eachShopOpening: 'Por loja aberta',
      minimumOpeningsPerMonth: 'Min. aberturas por mês',
      eachShopManagement: 'Por loja gerida',
      minimumManagementMonths: 'Min. de meses de gestão',
    },
    loading: 'A carregar',
    signin: 'Inicio de sessão',
    byName: 'Nome',
    byFiscal: 'Dados de faturação',
    placeHolderByName: 'Procurar por nome',
    placeHolderByFiscal: 'Procurar por motivo ou nif',
    type: 'Tipo',
    shop: 'Loja',
    codeExp: 'Código de expedição',
    agency: {
      code: 'Código de agência',
      name: 'Nome de agência',
    },
    status: 'Estado',
    deliveryStatus: 'Estado de logística directa',
    pickupStatus: 'Estado de logística inversa',
    tracking: {
      derivedAt: 'Derivado',
      receivedFromDriverAt: 'Recibido do motorista',
      receivedFromCustomerAt: 'Recibido do cliente',
      expiredAt: 'Expirado',
      deliveredToCustomerAt: 'Entregue ao cliente',
      returnedToDriverAt: 'Devolvido ao motorista',
      deliveredToDriverAt: 'Entregue ao motorista',
    },

    deliveredOrReturned: 'Entregue / devolvido',
    sell: 'Venda',
    sellplus: 'Venda +',
    networkPercentage: 'Percentagem por rede',
    shopPercentage: 'Percentagem por loja',
    reportQualities: {
      label: 'Relatórios*',
      notReceived: 'Pacotes entregues em PS e não recebidos',
      withoutRegisteredOutput: 'Paquetes recepcionados en PS sin salida registrada',
      referenceTypeSix: 'Casos Vendas sem referência 6',
    },
  },
  errors: {
    required: 'Requerido',
    CPWrong: 'Introduza um código postal válido',
    enterSquareCode: 'Introduza um código de plaza',
    enterClientCode: 'Introduza um código de cliente',
    min: 'Mínimo {{number}} caracteres',
    country: 'ISO 3166-1 alpha 2 country code',
    allRequiredOrNone: 'Todos os campos requeridos ou nenhum',
    password: 'Tem de ter pelo menos um número, uma maiúscula e uma minúscula',
    uuid: 'Tem de ser um UUID válido',
    enterName: 'Introduza um nome',
    enterNif: 'Introduza um NIF',
    enterUsername: 'Introduza um nome de utilizador',
    enterEmail: 'Introduza um email válido',
    emailAndLabel: 'Email e etiqueta requeridos ou nenhum',
    positiveNumber: 'Tem de ser um número positivo',
    paymentMethod: 'Tem de ser transferência ou confirmando',
    channelingAgencyCode: 'Agência de canalização direta',
    iban: 'Número de IBAN incorrecto',
    rangeHourOrEmpty: 'Tem de ser um intervalo horário válido ou um campo vazio',
    closeHourAfterOpenHour: 'A hora de fecho tem de ser posterior à hora de abertura',
    afternoonHourAfterMorningHour: 'O horário da tarde tem de ser posterior ao horário da manhã',
    latitude: 'Latitude tem de ser entre -90 e 90',
    longitude: 'Longitude tem de ser entre -180 e 180',
    latitudeNot0: 'Latitude não pode ser 0',
    longitudeNot0: 'Longitude não pode ser 0',
    number: 'Tem de ser um número',
    username: 'Tem de ser maiúsculas, minúsculas ou números',
    nifError: 'DNI, NIF ou Passporte incorrecto',
  },
};
