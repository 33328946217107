export default {
  contactForm: {
    nameSurname: 'Nombre y Apellido',
    number: 'Teléfono',
    email: 'Correo electrónico',
  },
  sections: {
    contactInformation: 'Información de contacto',
    personalData: 'Datos personales',
    emailAndPhone: 'Email y teléfono',
    address: 'Dirección',
    generalData: 'Datos generales',
    information: 'Información',
    fiscalData: 'Datos fiscales',
    fiscalAddress: 'Dirección fiscal',
    contactPerson: 'Persona de contacto',
    rates: 'Tarifas',
    networkRate: 'Tarifas de red',
    shopRate: 'Tarifas de tienda',
    salesRepresentative: 'Comercial',
    capabilities: 'Capacidades',
    status: 'Estado',
    openingSchedule: 'Horario de apertura',
    schedule: 'Horario',
    commissions: 'Comisiones',
    commissionsData: 'Datos de comisiones',
    salesSettings: 'Configuración de ventas',
  },
  infoSections: {
    zones: 'Puedes seleccionar mas de una zona.',
    rateHierarchy: {
      global:
        'Tarifas globales para las redes y tiendas que no tienen tarifas específicas. <br/><br/> <b>Estas tarifas se aplican a expediciones de logística directa e inversa.</b>',
      network:
        'Introducir tarifas expecificas para que en esta red se paguen precios diferentes a la red y a sus tiendas. <br/><br/> Si no seleccionar tarifas por defecto para aplicar la configuración global.',
      shop: 'Introducir tarifas expecificas para que en esta tienda se paguen precios diferentes a la red y a la tienda. <br/><br/> Si no seleccionar tarifas por defecto para aplicar la configuración de la red o si no la hay la global.',
    },
    networkRate: {
      info: 'El precio que se paga a la <b>red</b> por expedicion es la suma de los precios de cada paquete, donde cada paquete se calcula como: <br/><br/> <b>Precio por paquete = Precio Fijo + (Peso del paquete - Umbral) * Precio por kg adicional</b>',
    },
    shopRate: {
      info: 'El precio que se paga a la <b>tienda</b> por expedicion es la suma de los precios de cada paquete, donde cada paquete se calcula como: <br/><br/> <b>Precio por paquete = Precio Fijo + (Peso del paquete - Umbral) * Precio por kg adicional</b>',
    },
    schedule:
      'Para cada día pon un horario si no cierran al mediodía, pon ambos si cierran al mediodía, o no pongas nada si ese día está cerrado',
  },
  labels: {
    name: 'Nombre',
    username: 'Nombre de usuario',
    dni: 'DNI',
    nif: 'NIF',
    idNum: 'DNI',
    password: 'Contraseña',
    tooglePassword: 'Ver contraseña',
    loginEnabled: 'Habilitado',
    squareCode: 'Código plaza',
    clientCode: 'Código cliente',
    parcelShopId: 'Parcel Shop Id',
    reportOperations: {
      label: 'Redes*',
      status: 'Estado',
    },
    email: {
      label: 'Etiqueta',
      address: 'Email',
    },
    dialogs: {
      title: 'ATENCIÓN',
      capabilitiesNotSelected:
        'Al cambiar de una red premium las capacidades se han borrado, por favor rellene las correspondientes a la nueva red.',
      buttonOK: 'OK',
    },
    phone: {
      label: 'Etiqueta',
      number: 'Teléfono',
    },
    address: {
      address: 'Dirección',
      postalCode: 'Código postal',
      locality: 'Localidad',
      country: 'País',
      latitude: 'Latitud',
      longitude: 'Longitud',
      door: 'Puerta',
    },
    searchGoogle: 'Busca dirección en Google',
    zones: 'Zonas',
    enabled: 'Habilitado',
    businessName: 'Nombre del negocio',
    activity: 'Actividad',
    channelingAgencyCode: 'Agencia de Canalización Directa',
    retention: 'Retencion',
    iban: 'IBAN',
    paymentMethod: 'Método de pago',
    dueDays: 'Días de vencimiento',
    useDefaultValues: 'Usar tarifas por defecto',
    networkRate: {
      constant: 'Precio fijo',
      variableStart: 'Umbral de peso en kg',
      variable: 'Precio por kg addicional',
    },
    shopRate: {
      constant: 'Precio fijo',
      variableStart: 'Umbral de peso en kg',
      variable: 'Precio por kg addicional',
    },
    countries: {
      spain: 'España',
      portugal: 'Portugal',
    },
    titleExcelDialog: 'Atención',
    contextExcelDialog: 'El siguiente resultado no devuelve datos, pruebe a cambiar los parametros del informe.',
    accept: 'Aceptar',
    network: 'Red',
    zone: 'Zona',
    id: 'Uuid',
    timezones: 'Zonas horarias',
    isEco: 'Tienda Eco',
    isGold: 'PS Gold',
    QRDinamic: 'QR Dinamico',
    isRadical: 'PS Radical',
    openingRepresentative: 'Comercial de apertura',
    countryShop: 'Pais de las tiendas',
    since: 'Desde',
    to: 'Hasta',
    managingRepresentative: 'Comercial de gestión',
    deliver: 'Entrega',
    pickup: 'Recogida',
    reason: 'Razón',
    definitiveClosure: 'Cierre definitivo',
    temporaryClosure: 'Cierre temporal',
    switchSchedule: 'Mismo horario de lunes a viernes',
    firstSchedule: 'Horario',
    secondSchedule: 'Segundo horario',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    weekly: 'Lunes a viernes',
    all: 'Todos',
    incoming: 'Por llegar',
    inShop: 'En tienda',
    inShopExpired: 'Caducado',
    delivered: 'Entregrado',
    searchByName: 'Buscar por nombre',
    commissions: {
      eachShopOpening: 'Por tienda abierta',
      minimumOpeningsPerMonth: 'Min. aperturas por mes',
      eachShopManagement: 'Por tienda gestionada',
      minimumManagementMonths: 'Min. de meses de gestión',
    },
    loading: 'Cargando',
    signin: 'Inicio de sesión',
    byName: 'Nombre',
    byFiscal: 'Datos fiscales',
    placeHolderByName: 'Buscar por nombre',
    placeHolderByFiscal: 'Buscar por razón o nif',
    type: 'Tipo',
    shop: 'Tienda',
    codeExp: 'Código de expedición',
    agency: {
      code: 'Código de agencia',
      name: 'Nombre de agencia',
    },
    status: 'Estado',
    deliveryStatus: 'Estado de logística directa',
    pickupStatus: 'Estado de logística inversa',
    tracking: {
      derivedAt: 'Derivado',
      receivedFromDriverAt: 'Recibido de conductor',
      receivedFromCustomerAt: 'Recibido de cliente',
      expiredAt: 'Caducado',
      deliveredToCustomerAt: 'Entregado al cliente',
      returnedToDriverAt: 'Devuelto al conductor',
      deliveredToDriverAt: 'Entregado al conductor',
    },
    deliveredOrReturned: 'Entregado / devuelto',
    sell: 'Venta',
    sellplus: 'Venta +',
    networkPercentage: 'Porcentage por red',
    shopPercentage: 'Porcentage por tienda',
    reportQualities: {
      label: 'Informe*',
      notReceived: 'Paquetes entregados en PS y no Recepcionados',
      withoutRegisteredOutput: 'Paquetes recepcionados en PS sin salida registrada',
      referenceTypeSix: 'Casos Ventas sin referencia 6',
    },
  },
  errors: {
    required: 'Requerido',
    CPWrong: 'Introduzca un código postal válido',
    enterSquareCode: 'Introduzca un código de plaza',
    enterClientCode: 'Introduzca un código de cliente',
    min: 'Mínimo {{number}} caracteres',
    country: 'ISO 3166-1 alpha 2 country code',
    allRequiredOrNone: 'Todos los campos requeridos o ninguno',
    password: 'Tiene que tener al menos un número, una mayúscula y una minúscula',
    uuid: 'Tiene que se un UUID válido',
    enterName: 'Introduzca un nombre',
    enterNif: 'Introduzca un NIF',
    enterUsername: 'Introduzca un nombre de usuario',
    enterEmail: 'Introduzca un correo electrónico válido',
    emailAndLabel: 'Correo electrónico y etiqueta requeridos o ninguno',
    positiveNumber: 'Tiene que ser un número positivo',
    paymentMethod: 'Tiene que ser transferencia o confirming',
    iban: 'Número de IBAN incorrecto',
    rangeHourOrEmpty: 'Tiene que ser un rango horario válido o un campo vacío',
    closeHourAfterOpenHour: 'La hora de cierre tiene que ser posterior a la hora de apertura',
    afternoonHourAfterMorningHour: 'El horario de tarde tiene que ser posterior al horario de mañana',
    latitude: 'Latitud tiene que ser entre -90 y 90',
    longitude: 'Longitud tiene que ser entre -180 y 180',
    latitudeNot0: 'Latitud no puede ser 0',
    longitudeNot0: 'Longitud no puede ser 0',
    number: 'Tiene que ser un número',
    username: 'Tiene que ser mayúsculas, minúsculas o números',
    nifError: 'DNI, NIE o Passport incorrecto',
  },
};
